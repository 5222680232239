/* Custom styles for the phone input field to look like outlined mui input */

.PhoneInputInput {
  padding: 18.5px 14px;
  border-radius: 4px;
  background-color: #FFFFFF;
  border: 1px solid #cfd1d2;
}

.PhoneInputInput:hover {
  border: 1px solid #dab2b5;
}

.PhoneInputInput:focus {
  border: 2px solid #fd0d1d;
  outline: none;
}
