@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: Georgia;
  font-weight: 400;
  src: url(/static/media/Georgia.5b8bb278.ttf);
}

/* Open-Sans - 400, 600 & 700 weights */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@media screen and (max-width: 760px) {
  div.nice-dates-popover {
    max-width: 96%;
    margin: 2%;
    bottom: 0;
    left: 0;
  }
}

/* Adyen Drop In widget styles overwrite*/
body .adyen-checkout__button--pay {
  background-color: #D7225E;
  border-radius: 4px;
  font-family: Gotham, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 500;
}

body .adyen-checkout__button--pay:hover {
  background-color: #D7225E;
}

body .adyen-checkout__payment-method--selected {
  border: 1px solid rgba(47, 56, 61, 0.23);
  background: unset;
}

body .adyen-checkout__input-wrapper .adyen-checkout__input {
  border: 1px solid rgba(47, 56, 61, 0.23);
}

body
  .adyen-checkout__payment-method__radio.adyen-checkout__payment-method__radio--selected {
  background-color: #D7225E;
}

.adyen-checkout__payment-method__details .adyen-pay-btn {
  background-color: #D7225E;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  height: 40px;
  margin: 0;
  padding: 15px;
  text-decoration: none;
  transition: background 0.3s ease-out, box-shadow 0.3s ease-out;
  width: 100%;
  margin-bottom: 20px;
}

.adyen-checkout__input {
  font-size: 16px; /* Needed to prevent the widget causing zoom on ios safari */
}

/* Custom styles for the phone input field to look like outlined mui input */

.PhoneInputInput {
  padding: 18.5px 14px;
  border-radius: 4px;
  background-color: #FFFFFF;
  border: 1px solid #cfd1d2;
}

.PhoneInputInput:hover {
  border: 1px solid #dab2b5;
}

.PhoneInputInput:focus {
  border: 2px solid #fd0d1d;
  outline: none;
}


