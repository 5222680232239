/* Adyen Drop In widget styles overwrite*/
body .adyen-checkout__button--pay {
  background-color: #D7225E;
  border-radius: 4px;
  font-family: Gotham, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 500;
}

body .adyen-checkout__button--pay:hover {
  background-color: #D7225E;
}

body .adyen-checkout__payment-method--selected {
  border: 1px solid rgba(47, 56, 61, 0.23);
  background: unset;
}

body .adyen-checkout__input-wrapper .adyen-checkout__input {
  border: 1px solid rgba(47, 56, 61, 0.23);
}

body
  .adyen-checkout__payment-method__radio.adyen-checkout__payment-method__radio--selected {
  background-color: #D7225E;
}

.adyen-checkout__payment-method__details .adyen-pay-btn {
  background-color: #D7225E;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  height: 40px;
  margin: 0;
  padding: 15px;
  text-decoration: none;
  transition: background 0.3s ease-out, box-shadow 0.3s ease-out;
  width: 100%;
  margin-bottom: 20px;
}

.adyen-checkout__input {
  font-size: 16px; /* Needed to prevent the widget causing zoom on ios safari */
}
